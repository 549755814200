import { db } from '@/shared/firebase'

const UpdateOrderPaidStatus = async invoiceCollectionId => {
  const batch = db.batch()
  const order = await db
    .collection('orders')
    .where('invoiceCollectionId', '==', invoiceCollectionId)
    .get()
    .then(snapshot => {
      return snapshot.docs.length !== 0 ? { ...snapshot.docs[0].data(), id: snapshot.docs[0].id } : null
    })
  const paidAmount = await db
    .collection('invoices')
    .where('invoiceCollectionId', '==', invoiceCollectionId)
    .get()
    .then(snapshot => {
      let paidAmount = 0
      snapshot.docs.forEach(doc => {
        paidAmount += doc.data().paidAmount || 0
      })
      return parseFloat(paidAmount.toFixed(2))
    })

  const invoiceCollection = await db
    .doc(`invoiceCollections/${invoiceCollectionId}`)
    .get()
    .then(doc => doc.data())
  const invoiceCollectionSum = parseFloat(invoiceCollection.sums.gross.total.toFixed(2))
  const completelyPaid = invoiceCollectionSum === paidAmount
  const update = {
    completelyPaid,
    paidAmount
  }
  batch.update(db.doc(`invoiceCollections/${invoiceCollectionId}`), update)
  if (order) batch.update(db.doc(`orders/${order.id}`), update)
  return await batch.commit()
}

export default UpdateOrderPaidStatus
