<template>
  <div>
    <h1>New Credit Note</h1>
    <form class="d-flex justify-content-start mb-5" @submit.prevent="loadInvoice">
      <div class="input-group mr-2" style="width: 120px;">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">S-</span>
        </div>
        <input type="text" class="form-control" v-model="invoiceCollectionIdInput" />
      </div>
      <div class="input-group mr-2" style="width: 120px;">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">/</span>
        </div>
        <input type="text" class="form-control" v-model="invoiceIdInput" />
      </div>
      <button class="btn btn-primary" type="submit">Load Invoice Data</button>
    </form>
    <div>
      <pre v-if="address" class="ml-3 mt-3 mb-1"
        >{{ address.name || address.name1 }}
{{ address.name2 }}
{{ address.street }} {{ address.streetNumber }}
{{ address.zip }} {{ address.city }}
      </pre>
    </div>
    <button
      class="btn btn-sm btn-outline-danger mb-3"
      :style="`visibility: ${selectedPositions.length === 0 ? 'hidden' : 'visible'}`"
      @click="removeSelectedPositions"
    >
      Remove Selected
    </button>
    <table class="table" v-if="positions">
      <thead class="thead-light">
        <tr>
          <th class="align-middle text-right" style="width: 40px;">
            <input
              type="checkbox"
              @change="toggleSelectAllPositions"
              id="selectAllPositions"
              :checked="selectedPositions.length === positions.length"
            />
          </th>
          <th><label for="selectAllPositions" class="m-0">Position</label></th>
          <th>Net</th>
          <th>Tax 7 %</th>
          <th>Tax 19 %</th>
          <th>Gross</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(position, i) in positions" :key="`${i}-${position.productId}`">
          <td class="align-middle text-right"><input type="checkbox" v-model="selectedPositions" :value="i" /></td>
          <td>
            <strong>
              <select
                v-if="position.maxAmount"
                :value="position.amount"
                @change="recalculateCreditNote(position, parseFloat($event.target.value))"
                class="custom-select w-auto mr-1"
              >
                <option
                  v-for="(value, j) in Array.from({ length: position.maxAmount }, (_, i) => i + 1)"
                  :value="value"
                  :key="j"
                  :disabled="position.maxAmount === 1"
                >
                  {{ value }}x
                </option>
              </select>
              {{ position.title }}
            </strong>
          </td>
          <td>{{ formatCurrency(position.sums.net.total) }}</td>
          <td>{{ formatCurrency(position.sums.tax[7]) }}</td>
          <td>{{ formatCurrency(position.sums.tax[19]) }}</td>
          <td>{{ formatCurrency(position.sums.gross.total) }}</td>
          <td width="70"><button class="btn btn-sm btn-outline-danger" @click="deletePosition(i)">Delete</button></td>
        </tr>
      </tbody>
      <tfoot>
        <td></td>
        <td>
          <strong>{{ formatCurrency(sums.net.total) }}</strong>
        </td>
        <td>
          <strong>{{ formatCurrency(sums.tax[7]) }}</strong>
        </td>
        <td>
          <strong>{{ formatCurrency(sums.tax[19]) }}</strong>
        </td>
        <td>
          <strong>{{ formatCurrency(sums.gross.total) }}</strong>
        </td>
        <td></td>
      </tfoot>
    </table>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" v-if="positions" @click="createCreditNote">Create Credit Note</button>
    </div>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import UpdateOrderPaidStatus from '@/helpers/transactions/UpdateOrderPaidStatus'

export default {
  data() {
    return {
      invoiceCollectionIdInput: '',
      invoiceIdInput: '',
      address: null,
      organizationId: null,
      positions: null,
      selectedPositions: [],
      fullPositions: null
    }
  },
  computed: {
    invoiceCollectionId() {
      return `S-${this.invoiceCollectionIdInput}`
    },
    invoiceId() {
      return `S-${this.invoiceCollectionIdInput}_${this.invoiceIdInput}`
    },
    sums() {
      let sums = {
        gross: { total: 0, 7: 0, 19: 0 },
        net: { total: 0, 7: 0, 19: 0 },
        tax: { total: 0, 7: 0, 19: 0 }
      }
      this.positions.forEach(position => {
        sums = mergeSumObjects(sums, position.sums)
      })
      return sums
    }
  },
  methods: {
    async loadInvoice() {
      try {
        this.selectedPositions = []
        const invoice = await db
          .doc(`invoices/${this.invoiceId}`)
          .get()
          .then(doc => {
            if (!doc.exists) throw new Error('Invoice not found')
            return doc.data()
          })
        this.address = invoice.address
        this.positions = invoice.positions
        this.organizationId = invoice.organizationId
        this.positions = this.positions.map(position => {
          if (position.amount > 1) position.title = position.title.replace(position.amount + 'x', '')
          position.maxAmount = position.amount
          return position
        })
        if (invoice.containerDepositSums) {
          Object.keys(invoice.containerDepositSums).forEach(key => {
            if (key !== 'total') {
              this.positions.push({
                title: `Pfand ${key.replace('.', ',')} €`,
                amount: invoice.containerDepositSums[key].amount,
                maxAmount: invoice.containerDepositSums[key].amount,
                sums: {
                  gross: invoice.containerDepositSums[key].gross,
                  net: invoice.containerDepositSums[key].net,
                  tax: invoice.containerDepositSums[key].tax
                }
              })
            }
          })
        }
        if (invoice.deliveryFeeSums) {
          this.positions.push({
            title: 'Liefergebühr',
            amount: 1,
            sums: invoice.deliveryFeeSums
          })
        }
      } catch (error) {
        alert(error.message)
      }
    },
    toggleSelectAllPositions(e) {
      if (e.target.checked) {
        this.selectedPositions = Array.from(Array(this.positions.length).keys())
      } else {
        this.selectedPositions = []
      }
    },
    removeSelectedPositions() {
      this.positions = this.positions.filter((pos, i) => !this.selectedPositions.includes(i))
      this.selectedPositions = []
    },
    deletePosition(index) {
      this.positions.splice(index, 1)
    },
    async createCreditNote() {
      const { address, positions, organizationId, sums, invoiceCollectionId, invoiceId } = this
      positions.forEach(position => {
        if (position.title.search('Pfand') !== -1 && position.amount === 1) position.title = `1x ${position.title}`
      })

      const creditNote = {
        address: {
          name1: address.name1 || address.name,
          street: address.street,
          streetNumber: address.streetNumber,
          zip: address.zip,
          city: address.city,
          ...(typeof address.name2 !== 'undefined' && { name2: address.name2 })
        },
        createdAt: new Date(),
        date: { creditNote: new Date() },
        documentGenerated: false,
        invoiceId,
        organizationId,
        positions,
        sums
      }

      const creditNoteId = await db
        .collection('creditNotes')
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get()
        .then(snapshot => {
          return 'EG' + (parseFloat(snapshot.docs[0].id.slice(2)) + 1)
        })

      const invoice = await this.$get(`invoices/${creditNote.invoiceId}`, { returnId: false })

      if (!invoice.transactions) invoice.transactions = []

      invoice.transactions.push({
        amount: parseFloat(sums.gross.total.toFixed(2)),
        assignedAt: new Date(),
        date: creditNote.date.creditNote.toISOString().substr(0, 10),
        creditNoteId,
        type: 'creditNote'
      })

      invoice.paidAmount = parseFloat(
        invoice.transactions
          .map(a => a.amount)
          .reduce((a, b) => a + b)
          .toFixed(2)
      )

      invoice.completelyPaid = invoice.paidAmount === parseFloat(invoice.sums.gross.total.toFixed(2))

      await db.doc(`invoices/${creditNote.invoiceId}`).update(invoice)
      await db.doc(`creditNotes/${creditNoteId}`).set(creditNote)
      await UpdateOrderPaidStatus(invoiceCollectionId)
      this.$router.push('/creditnotes')
    },
    recalculateCreditNote(position, selectedAmount) {
      const taxKeys = [7, 19, 'total']
      const keys = ['tax', 'gross']

      taxKeys.map(taxKey => {
        keys.map(key => {
          position.sums[key][taxKey] = parseFloat(
            ((position.sums[key][taxKey] / position.amount) * selectedAmount).toFixed(2)
          )
        })
        position.sums.net[taxKey] = position.sums.gross[taxKey] - position.sums.tax[taxKey]
      })

      position.amount = selectedAmount
    }
  }
}

const mergeSumObjects = (sums1, sums2) => {
  Object.keys(sums2).forEach(key1 => {
    Object.keys(sums2[key1]).forEach(key2 => {
      sums1[key1][key2] = sums1[key1][key2] + sums2[key1][key2]
    })
  })
  return sums1
}
</script>
